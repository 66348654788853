import React from "react";
import { Link } from "gatsby";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";
import { currentYear } from "../../components/Helpers";
import ImgScreenshot from "../../components/ImgScreenshot.js";
import ImgContainerFixed from "../../components/ImgContainerFixed";
import H from "../../components/Headline";

const breadCrumbLevels = {
  Home: "/",
  "Analytics Consulting": "/en/analytics-consulting",
  "Event Tracking": "/en/event-tracking"
};

// hreflang data
const alternateLangs = [
  {
    hreflang: "en",
    href: "/en/event-tracking"
  },
  {
    hreflang: "de",
    href: "/de/event-tracking"
  },
  {
    hreflang: "x-default",
    href: "/en/event-tracking"
  }
];

const EventTracking = props => (
  <Layout location={props.location} alternateLangs={alternateLangs}>

    <React.Fragment>
      <SEO
        title={`Event Tracking in Google Analytics [Setup Guide ${currentYear}]`}
        description=" Guide to configure event tracking for modern Google Analytics together with Google Tag Manager. Tutorial with screenshots and explanations from certified expert."
        lang="en"
        canonical="/en/event-tracking"
        image="google-analytics-event-tracking-report-hero"
        alternateLangs={alternateLangs}
        datePublished="2020-09-05T04:32:43.188Z"
        dateModified="2023-11-16T06:56:07Z"
      />
      <MainContent article>
        <ImgScreenshot
          src="event-tracking/google-analytics-event-tracking-report-hero.png"
          alt="event data graphfrom Google Analytics event reporting"
        />
        <Breadcrumb breadCrumbLevels={breadCrumbLevels} />
        <H as="h1">Event Tracking</H>
        <p>
          Configuring Google Analytics event tracking is vital to data analysis, because it is used to track user
          interactions. Analysing pageviews, sessions and visitors only lags context about the engagement of a website
          visitor.
        </p>{" "}
        <p>
          This is solved by collecting additional data from custom events that capture user interactions, such as link
          clicks for example.
        </p>
        <p>
          Events represent user interactions or occurrences.
          <br />
          Usually, the most important interactions stand in relation to the goal of the website.
          <br /> Hence, we set up custom event tracking to use it as a performance indicator to see, if our goals are
          being achieved.
        </p>
        <p>
          Events can be grouped into <strong>hard conversions</strong> and <strong>soft conversions</strong> to
          account for their relation with the overall goal of the website.
        </p>
        <p>
          Since events often are the basis to monitor conversions in an ecommerce or remarketing context, it is
          sometimes referred to as conversion tracking.
          <br />I distinguish between conversion tracking and tracking an event, since a conversion could also be
          a pageview or a <b>passive</b> occurence, as for example spending a certain amount of time on a landing
          page.
        </p>
        <p>
          To illustrate what event tracking means, I will show you how to set up <strong>click tracking</strong> on a
          submit button. Afterwards, you should be able to set up your own unique click events on any element you like - so
          let's get started!
        </p>
        <H as="h2">What is event tracking in Google Analytics?</H>
        <p>
          An event can either be an <strong>active user interaction</strong> or a <strong>passive occurrence</strong>.
          Tracking them means to watch or count the occurrences and set them in relation to users, visits and other
          dimensions for later analysis.
        </p>
        <p>Since you are free to configure events the way you want, they can take many different forms.</p>
        <H as="h3">Examples</H>
        <ul>
          <li>add a product to a shopping basket</li>
          <li>signup to an email list</li>
          <li>scroll down to the bottom of an article</li>
          <li>click play in a video</li>
          <li>user login</li>
          <li>click on an image to zoom</li>
          <li>submission of a contact form</li>
          <li>outbound link click</li>
          <li>print an article</li>
          <li>download a file</li>
        </ul>
        <p>
          As you can see, events are usually tied to something the user <em>does</em>, based on monitoring...
        </p>
        <ul>
          <li>mouse clicks</li>
          <li>mouse movements</li>
          <li>scrolling</li>
          <li>browser navigation</li>
          <li>keyboard inputs</li>
        </ul>
        <p>
          Events can also be <em>passive</em>, without the user doing anything actively.
          <br />
          For example, when the user spends a certain <strong>amount of time on a page</strong> or when a form returns
          an <strong>error</strong>. Also when a <strong>chatbot window</strong> opens up to talk to the visitor,
          there is no activity coming from the user.
        </p>
        <p>Though a <b>page view</b> is technically also an interaction, the most widely used Universal Google Analytics counts a page view not as an event. However, the new Google Analytics App + Web (currently in Beta) uses a different data model which tracks page visits as events too.</p>
        <H as="h2">Event types</H>
        <p>We can distinguish between two types of events:</p>
        <H as="h6">Active events (with user interaction)</H>
        <ul>
          <li>mouse clicks, scroll tracking and mouse movement</li>
          <li>screen touch</li>
          <li>keyboard inputs</li>
        </ul>
        <H as="h6">Passive events</H>
        <ul>
          <li>hitting a predefined time threshold</li>
          <li>Server responses</li>
          <li>Programmatic validations</li>
          <li>Third-party script execution</li>
        </ul>
        <p>
          Note that with tracking active and passive events, you can re-construct a big part of the{" "}
          {/* <a href="/customer-journey"> */}
          customer journey
          {/* </a> */}
          . <br />
          Such data enables you to draw conclusions about the user's experience from beginning to end. If you find any
          flaws in the journey, you can now take action to reduce those negative experiences.
        </p>
        <H as="h2">Structure of Google Analytics Events</H>
        <p>
          Each event in Google Analytics holds some event fields, or bits of information about the event, that we can
          use to describe the occurrence:
        </p>
        <ul>
          <li>
            <strong>event category</strong> - to categorize events in groups. In the context of a contact form, for
            example, you would want to group all events into an event category <em>contact form</em>
          </li>
          <li>
            <strong>event action</strong> - to describe the interaction, e.g. <em>form submission</em>
          </li>
          <li>
            <strong>event label</strong> - to add additional information about the interaction. For example, if the
            user is sending the <em>address of their website</em> along with the form, you use it to distinguish
            between the received forms
          </li>
          <li>
            <strong>event value</strong> - (<em>optional</em>) to add a numeric value to the information. For
            instance, if on avg. every 25th contact leads to a 100$ conversion, we could assign a value of <em>4</em>{" "}
            to each event.
          </li>
          <li>
            <strong>event non-interaction</strong> - to distinguish between an active or passive user interaction.{" "}
            <br />
            By default, it is set to <code>false</code>, which means the event counts as active.{" "}
            <strong>Important:</strong> Active events affect the calculation of a page's <b> <Link to="/en/wiki-analytics/bounce-rate">bounce rate</Link></b>, by
            lowering it. So if you ever struggle with an unrealisticly low bounce rate, chances are some events
            user-interaction is set to <code>true</code> by mistake.
          </li>
        </ul>
        <H as="h2">Implement Google Analytics event tracking</H>
        <p>There are two possible ways to implement custom event tracking:</p>
        <ol>
          <li>
            <strong>Implement it</strong> with javascript snippets on all relevant HTML elements along
            with an onclick event handler - <strong>I don’t recommend this</strong> approach, since it is error-prone
            and not scalable. If you want to do this anyway, you can find code examples in{" "}
            <strong>Google’s event tracking documentation</strong>:
          </li>
        </ol>
        <ul>
          <li>
            <a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/events" target="_blank">
              analytics.js event documentation (legacy)
            </a>
          </li>
          <li>
            <a href="https://developers.google.com/analytics/devguides/collection/gtagjs/events" target="_blank">
              gtag.js event documentation
            </a>
          </li>
        </ul>
        <ol start="2">
          <li>
            <strong>Setting it up with Google Tag Manager</strong> or another <Link to="/en/tag-management">tag management system</Link> of
            your choice. <br />
            Ease and flexibility when implementing changes, as well as scalability and easy integration with
            third-party services make it the preferred implementation method.
          </li>
        </ol>
        <p>
          I will explain how to track events with Google Tag Manager, since it is the most widely used tag
          management system everyone can get access to.
        </p><p>If you want to learn more about GTM, like how to set it up and track things like outbound links and downloads, read my <Link to="/en/google-tag-manager-setup">Google Tag Manager tutorial</Link>.</p>
        <H as="h2">Guide to track events with Google Tag Manager</H>
        <p>
          Setting up custom event tracking can be broken down into creating a <strong>trigger</strong> for the event, as well
          as creating an <strong>event tag</strong>, which populates the event properties (
          <em>category, action, label</em>, etc.). <br />
          The values for the event properties can either be assigned statically or dynamically - where the latter is
          preferred for scaling the configuration over larger sites.
        </p>
        <p>
          As an example, we are going to implement Google Analytics tracking for a button click.{" "}
          <strong>Click tracking</strong> is the most commonly used form of user behaviour tracking, so the example should be
          applicable to many other use cases.
        </p>
        <p>At this point I assume that you already created a tracking ID and that the tracking code was implemented across the whole site when you <Link to="/en/google-analytics-setup">set up Google Analytics</Link> with GTM.</p>

        <H as="h3">Configure the event trigger in Google Tag Manager</H>
        <ul>
          <li>
            First, we have to log into our <a href="https://tagmanager.google.com">Google Tag Manager container</a>{" "}
            and <strong>create a new trigger</strong>.
          </li>
        </ul>
        <ImgScreenshot
          src="event-tracking/google-tag-manager-event-tracking-new-trigger.png"
          alt="Set new trigger in Google Tag Manager"
          className="article-img"
        />
        <ul>
          <li>
            Give your trigger a <strong>name</strong>, for example <strong>Click - Button</strong>, and click on the{" "}
            <strong>trigger configuration</strong> field to select a trigger type. You will see a list of trigger
            types slide in from the right.
          </li>
          <li>
            Choose <strong>All Elements</strong> as the type and select, that the{" "}
            <strong>trigger only fires on some clicks</strong>.
          </li>
          <li>
            Then, in the first dropdown, choose <strong>Click Element</strong>. If it's not listed, just click{" "}
            <strong>Choose built in variable…</strong> and activate it.
          </li>
        </ul>
        <ImgScreenshot
          src="event-tracking/google-tag-manager-event-trigger-variable.png"
          alt="Trigger variable in Google Tag Manager screenshot"
          className="article-img"
        />
        <ul>
          <li>
            In the second dropdown, choose <strong>matches CSS selector</strong>.<br />
            Now let me explain what we’re doing:
            <br />
            We are trying to generate a click trigger for a specific HTML element on your website - the one you want
            to be tracking clicks on. In my example, it is a <strong>button</strong>, but it could also be an{" "}
            <strong>image</strong> or a <code>&lt;div&gt;</code> tag, or any other HTML tag - so the question is: How
            do we tell Google Tag Manager (GTM) which element to observe?
            <br />
            The Answer is: <strong>CSS selectors</strong> - You don’t know what that is? It is just a programmatic way
            of specifying HTML elements on the page and I will show you a way to get the CSS selector for any element
            with just a few mouse clicks.
          </li>
          <li>
            In Google's Chrome browser, <strong>right-click</strong> any element you would like to track clicks on and
            select <strong>inspect</strong>. Chrome Developer Tools will open up and by default, the HTML element will
            be pre-selected.
          </li>
          <li>
            Then you simply <strong>right-click</strong> the element and choose <strong>Copy</strong> &gt;{" "}
            <strong>Copy JS path</strong> (avoid <em>copy selector</em> - it returns faulty selectors from time to
            time).
          </li>
        </ul>
        <ImgContainerFixed width="529px">
          <ImgScreenshot
            src="event-tracking/google-developer-tools-copy-css-selector-for-button-tracking.png"
            alt="Copy CSS selector for button click tracking"
            className="article-img"
          />
        </ImgContainerFixed>
        <ul>
          <li>
            If you paste the content of your clipboard into a text editor, you will see you copied something like:
            <br />
            <br />
            <code>document.querySelector(&quot;body &gt; ul &gt; button&quot;)</code>
            <br />
            <br />
            We want to take the part that is inside the brackets, without the “”. So, this part:
            <br />
            <br />
            <code>body &gt; ul &gt; button</code>
          </li>
          <li>
            <strong>This is the CSS Selector</strong> we need to paste into the last field in our trigger
            configuration in GTM (<em>see below</em>).
            <br />
            <strong>Copy</strong> that value and paste it in. Click <strong>save</strong>.
          </li>
        </ul>
        <ImgScreenshot
          src="event-tracking/google-tag-manager-event-trigger-configuration.png"
          alt="Event trigger configuration in Google Tag Manager screenshot"
          className="article-img"
        />
        <p>Notice you could do this with any element and set up any event trigger in GTM as you like?</p>
        <p>
          We are not done yet. After setting up the trigger conditions for our event, we have to configure the Google
          Analytics event tag to make sure the right data is sent to the Google Analytics property.
        </p>
        <H as="h3">Create a custom event tag</H>
        <p>
          Now that we have a trigger, we need to combine it with an actual event tag. You can think of triggers and
          tags as the <em>when</em> and <em>what</em> in our example: <br /> The trigger defines <em>when</em> we want
          to track an event (<em>when our button is clicked</em>), and now we will define a GA event, that describes{" "}
          <em>what</em> we do: <em>Send an event to GA including all the right values for the event properties.</em>
        </p>
        <p>Let’s get started.</p>
        <ul>
          <li>
            In Google Tag Manager go to the tags menu and <em>create a new tag</em>.
          </li>
        </ul>
        <ImgScreenshot
          src="event-tracking/google-tag-manager-create-new-tag-screenshot.png"
          alt="Google Tag Manager create custom event tag"
          className="article-img"
        />
        <ul>
          <li>
            Give the tag a <strong>name</strong> like <em>Button Event</em> and click on{" "}
            <strong>tag configuration</strong>.
          </li>
          <li>
            A panel from the right side is going to slide in with a list of possible tag types to choose from. Select{" "}
            <strong>Google Analytics - Universal Analytics</strong>.
          </li>
          <li>
            Back in the tag configuration, set the <strong>track type</strong> to <strong>event</strong>. Now you will
            see more fields showing up for the event data.
          </li>
          <li>
            Let’s assign static values for <strong>event category</strong> and <strong>action</strong> and let’s make
            the <strong>event label</strong> populate dynamically.
            <br />
            Call the <strong>category</strong> <em>click events</em>, for example, the <strong>action</strong> is just{" "}
            <em>click</em> and for the <strong>label</strong> click on the Lego brick symbol next to it.
          </li>
          <li>
            We want the label to always hold the text that is written on the clicked HTML element, so in case we are
            tracking multiple buttons, we could distinguish between them.
            <br />
            After clicking on the Lego brick, a panel is going to slide in to <strong>choose a variable</strong>.
            Click on <strong>built-ins</strong> in the top right corner, unless you already see{" "}
            <strong>click text</strong> in the list of variables (then just <strong>select it</strong> and skip the
            following step).
          </li>
        </ul>
        <ImgScreenshot
          src="event-tracking/google-tag-manager-select-built-in-variable-for-event-label.png"
          alt="built-in variable for event label in google tag manager"
          className="article-img"
        />
        <ul>
          <li>
            In the list of built-in variables, activate <strong>click text</strong> and GTM will add that variable in
            the label field in curly braces, like this: <code>{"{{Click Text}}"}</code> - Double curly braces are the
            internal way in GTM to reference variables.
          </li>
          <li>
            The <strong>value</strong> field in the tag configuration can be filled with a value the event click may
            represent. <br />
            In my example, I leave the field <strong>empty</strong> because I don’t have any meaningful monetary value
            for the button click.
          </li>
          <li>
            If the event is <strong>interactive</strong>, select <strong>false</strong> for the non-interaction hit.
            If it is a <strong>passive event</strong>, select <strong>true</strong>.
          </li>
          <li>
            In the <strong>Google Analytics settings</strong> variable, select the variable you configured when you{" "}
            <a href="/en/google-analytics-setup">set up Google Analytics</a>.
          </li>
          <li>
            If you have not configured a <strong>settings variable</strong>, just set a checkmark for{" "}
            <em>Enable overriding settings in this tag</em> and add the tracking ID (e.g. <em>UA-12345678-91</em>) from your{" "}
            <Link to="/en/google-analytics-setup#where-to-get-the-google-analytics-tracking-code">
              Google Analytics tracking code
            </Link>
            .
          </li>
          <li>
            Finally, scroll down to the <strong>triggering</strong> configuration, <strong>click on it</strong> and
            select the trigger we configured shortly before: <em>Click - Button</em>
          </li>
        </ul>
        <p>The entire event tag configuration at once:</p>
        <ImgContainerFixed width="371px">
          <ImgScreenshot
            src="event-tracking/google-tag-manager-event-tag-configuration-screenshot.png"
            alt="GTM event tag configuration"
            className="article-img"
          />
        </ImgContainerFixed>
        <ul>
          <li>
            Now click <strong>save</strong> in the top right corner and you are done setting up the GA event.
          </li>
        </ul>
        <p>Great! The next step is to doublecheck if our onclick tracking setup works.</p>
        <H as="h2">Debugging events</H>
        <p>
          To debug the setup, enter the <strong>preview mode</strong> from the Google Tag Manager
          overview:
        </p>
        <ImgScreenshot
          src="event-tracking/google-tag-manager-event-trigger-preview.png"
          alt="Google tag manager preview mode"
          className="article-img"
        />
        <br />
        <p>
          Afterwards, we visit the page that holds the submit button. Due to the Google Tag Manager preview
          mode, we have certain <Link to="/en/wiki-analytics/cookies">cookies</Link> set in our browser, that will open a debugging window on the bottom of the
          page:
        </p>
        <ImgScreenshot
          src="event-tracking/google-tag-manager-debugging-mode.png"
          alt="google tag manager debugger for events"
          className="article-img"
        />
        <br />
        <p>
          If you now <strong>click on the button</strong> to test the event tracking, you should see a{" "}
          <strong>click</strong> appear in the left summary panel, and the main debug window should show that our
          event fired:
        </p>
        <ImgContainerFixed width="555px">
          <ImgScreenshot
            src="event-tracking/google-tag-manager-debugger-button-click.png"
            alt="google tag manager debugger click tracking"
            className="article-img"
          />
        </ImgContainerFixed>
        <br />
        <p>
          If you click on the <strong>button event</strong> you can verify the event fields that were sent along with
          the event hit:
        </p>
        <ImgContainerFixed width="496px">
          <ImgScreenshot
            src="event-tracking/google-tag-manager-debugger-event-properties-for-category-action-label.png"
            alt="google tag manager debugger event fields"
            className="article-img"
          />
        </ImgContainerFixed>
        <br />
        <p>
          See how the <strong>event label</strong> has been populated with “OK” automatically? That is because we used
          the <strong>click text</strong> built-in variable in Google Tag Manager, which always holds the text of the
          clicked element.
        </p>
        <p>More importantly, if you scroll further down, you can verify the rules for the event trigger:</p>
        <ImgContainerFixed width="413px">
          <ImgScreenshot
            src="event-tracking/google-tag-manager-event-trigger-rules.png"
            alt="google tag manager event trigger rules"
            className="article-img"
          />
        </ImgContainerFixed>
        <br />
        <p>
          <strong>TIP</strong>: To verify that your Google Analytics account successfully received all data, we can
          visit the <strong>realtime report for events</strong> in Google Analytics. In case a few minutes already
          passed by since the last button click, the <strong>viewing for the previous 30min</strong> should help.
        </p>
        <ImgScreenshot
          src="event-tracking/google-analytics-event-tracking-realtime-report.png"
          alt="google analytics realtime report for events"
          className="article-img"
        />
        <H as="h3">CSS Selector validation</H>
        <p>
          In case, the debugger did not notice your button clicks and did not show any click event, there is probably
          something wrong with the <strong>CSS selector</strong>.<br />
          <strong>TIP:</strong> you can open <strong>chrome developer tools</strong> on the page with the element you
          want to track by either pressing <strong>F12</strong> or <strong>right-click</strong> &gt;{" "}
          <strong>inspect</strong> and visit the <strong>elements</strong> tab. If you now press{" "}
          <strong>CTRL+F</strong> or <strong>CMD+F</strong> on Mac to search for an element, you can simply{" "}
          <strong>paste your CSS selector into the search field</strong> and dev tools will automatically jump to the
          element:
        </p>
        <ImgContainerFixed width="578px">
          <ImgScreenshot
            src="event-tracking/google-developer-tools-find-by-css-selector.png"
            alt="google developer tools find element with css selector"
            className="article-img"
          />
        </ImgContainerFixed>
        <p>
          This way you can verify if your CSS selector works. If dev tools is not jumping to any element in the code,
          there is something wrong with the selector. There are also <strong>browser extensions</strong> that can help
          you get the right selector.
        </p>
        <p>
          After debugging and fixing selectors we should finally have the click tracking running and can go back to
          Google Tag Manager to <strong>leave preview mode</strong> and <strong>submit the changes</strong>.
        </p>
        <p>
          Congratulations to you so far! You've configured event monitoring for clicking a button based on a CSS
          selector!
        </p>
        <p>
          With the setup working now, you can{" "}
          <Link to="/en/google-analytics-setup#how-to-set-up-goals-in-google-analytics">set up event goals</Link> in
          GA! Google Analytics goals are a helpful feature, as you can break down reports and see how many conversions
          have occurred for a given sub-segment.
        </p>
        <p>
          To set it up, you simply have to define a custom Google Analytics goal with the category, action and label
          fields matching your event and then it will be counted as an event goal.
        </p>
        <H as="h2">Advantages &amp; Disadvantages of tracking with CSS selectors</H>
        <p>
          It is important to mention that setting up event tracking based on CSS selectors is not the most robust way.
        </p>
        <p>
          The entire event setup will break, if the CSS selector doesn’t work. It is a <em>critical dependency</em>,
          which represents a weakness in this approach of setting it up.
        </p>
        <p>But why would the selector ever stop working?</p>
        <p>
          Because the bigger the website, the more changes are happening to a site, and the more people are involved -
          possibly simultaneously in different teams.
          <br /> As soon as a change effects the structure of our selector, it will stop working.
        </p>
        <p>
          A frontend developer may well add some changes to the page on some day in the future, which could cause the{" "}
          selector to break. Hence, it would be more robust to add an <strong>onclick event handler</strong> to the
          element, which pushes all event properties to a{" "}
          <strong>
            <Link to="/en/data-layer">data layer</Link>
          </strong>
          . This is the recommended approach for important KPIs and for big organizations, where changes are not
          overseen.
        </p>
        <p>Advanced web analytics implementations that integrate databases and third-party data sources use a <Link to="/en/data-layer#event-tracking-basend-on-a-data-layer">data layer to track events</Link>. Please read that article for the required event-tracking code.</p>
        <p>Using a data layer is more secure and reliable but requires a thorough implementation.</p>
        <p>Instead of relying on CSS selectors, you use event listeners for <a href="https://developer.mozilla.org/en-US/docs/Web/Events" target="_blank">browser events</a> as a trigger. GTM also uses click event listeners under the hood when you set up a click trigger, but when basing tracking on a data layer you can more freely choose which browser events should be tracked.</p>
        <p>Especially for a single page app (SPA) without page loads between page navigation, a data layer can be well used to track <b>virtual pageviews</b> as events and have them count as regular page views in Google Analytics.</p>
        <p>
          For smaller websites the effort of implementing a data layer is not always justifiable. In such cases event
          tracking based on CSS selectors is a valid approach.
        </p>
        <p>
          The <strong>advantage</strong> of using CSS selectors is clearly the <strong>ease</strong> of implementation and how{" "}
          <strong>quick</strong> you can start collecting data. This can be helpful too if a data layer implementation
          may take a while, due to a shortage of resources, because you may be able to already collect some of the
          data just with CSS selectors.
        </p>
        <p>
          The <strong>disadvantage</strong> is, that this way is almost <strong>prone to break</strong> someday,
          depending on how many changes are implemented and how many teams are working on the website.
        </p>
        <p>
          But if you are setting up web tracking for your own site and you are in control of the
          changes, feel free to follow this method. Simply be aware, that changes to the page's HTML structure can
          break the CSS selector.{" "}
        </p>
        <p>
          However, Companies with a budget and high requirements for data quality should choose a data layer as the
          foundation for their event tracking setup.
        </p>
        <H as="h2">Event reports in Google Analytics</H>
        <p>
          Now that we set up tracking for user interactions, we have to make sure we stay on top of the amounts of
          events that are sent to the Google Analytics account.{" "}
        </p>
        <p>
          The best way to do this is either with the built-in <strong>event report</strong>, with a{" "}
          <strong>custom report</strong> or a Google Analytics <strong>dashboard</strong>.
        </p>
        <p>
          In <strong>Google Analytics</strong>, you can go to <strong>Behavior</strong> &gt; <strong>Events</strong>{" "}
          &gt; <strong>Top Events</strong> to find the event data you tracked:
        </p>
        <ImgScreenshot
          src="event-tracking/google-analytics-event-tracking-report.png"
          alt="Google Analytics event tracking report overview"
          className="article-img"
        />
        <br />
        <p>
          Since there is no default <b>event name</b> available in GA, you will see your event categories first. You can also choose the primary dimension to be <strong>event action</strong> or{" "}
          <strong>event label</strong> to drill deeper into the report.
        </p>
        <p>
          Note that the events report gives us a column with <strong>Avg. Value</strong>, which comes in handy, if we need
          the average value of our click events. This may be relevant for an web app that calculates prices, where the
          final result is tracked with an event. This way, you could easily find the avg. value of a users
          calculations.{" "}
        </p>
        <p>
          Other common examples of useful avg. values are e.g. filter settings for prices or sizes in an e-commerce
          shop.{" "}
        </p>
        <p>
          So keep in mind, that an <strong>event value</strong> may seem unrelated at first, but there may well be a
          related value to assign to the event anyway, since it would be helpful to check average values across the
          board.
        </p>
      </MainContent>
    </React.Fragment>

  </Layout >
);

export default EventTracking;
